export const ADD_COMPETITION_BUTTON = `add__competition__button`;
export const ADD_COMPETITION_FORM = `add__competition__form`;
export const ADD_USER_BUTTON = `add__user__button`;

export const CANCEL_NEW_COMPETITION = `cancel__new__competition`;
export const CANCEL_VIEW_COMPETITION = `cancel__view__competition`;

export const JOIN_COMPETITION_BUTTON = `join__competition__button`;

export const LOGIN_BUTTON = `login__button`;

export const PARTICIPANTS = `competition__participants`;

export const REGISTERED_USERS = `registered__users`;

export const SAVE_NEW_COMPETITION = `save__new__competition`;
export const SIGNUP_BUTTON = `signup__button`;


