import AbstractView from './abstract';
import {SIGNUP_BUTTON} from '../utils/const';

const createSignupInvitation = () => {
    return `<article class="signup-invitation">
            <h2>Добро пожаловать!</h2>
            <p>Чтобы принять участие в соревновании, вам нужно зарегистрироваться.</p>
            <div>
                <button class='button light-button ${SIGNUP_BUTTON}'>Регистрация</button>
            </div>
        </article>`;
};

export default class Signup extends AbstractView {
    constructor() {
        super();

        this._clickHandler = this._clickHandler.bind(this);
    }

    getTemplate() {
        return createSignupInvitation();
    }

    _clickHandler(evt) {
        evt.preventDefault();
        this._callback.click();
    }

    setClickHandler(callback) {
        this._callback.click = callback;
        this.getElement().querySelector(`.${SIGNUP_BUTTON}`)
            .addEventListener('click', this._clickHandler);
    }
}
