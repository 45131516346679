import BoardPresenter from './presenters/board';
import firebase from "firebase/app";

const siteHeaderElement = document.querySelector(`.main-header`);
const siteMainElement = document.querySelector(`.main`);
const boardPresenter = new BoardPresenter(siteHeaderElement, siteMainElement);

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        console.info(`User ${user.displayName} sign in`);
        boardPresenter.login(user);
    } else {
        console.info('Signup required');
        boardPresenter.signup();
    }
})
