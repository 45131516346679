import { render, RenderPosition, PageMode, remove } from '../utils/render';
import { uiConfig, ui, db } from '../config';
import { saveNotificationEndCompetition } from '../utils/tools';

import UserModel from '../models/user';
import Competition from '../models/competition';

import SiteMenu from './site-menu';
import AddCompetitionPresenter from './add-competition';

import SignupView from '../views/signup';
import AddCompetitionButtonView from '../views/add-competition-button';
import ViewCompetition from '../views/view-competition';
import ListCompetitionsView from '../views/list-competitions';
import ShowNotifications from "../views/notification";
import {ListType} from '../views/list-competitions';

import firebase from 'firebase/app';


export default class Board {
    constructor(menuContainer, contentContainer) {
        this._menuContainer = menuContainer;
        this._contentContainer = contentContainer;

        this._menuPresenter = new SiteMenu(menuContainer, this, '');
        this._addCompetitionPresenter = new AddCompetitionPresenter(this._contentContainer, this);

        this._signupComponent = new SignupView();
        this._addCompetitionButtonComponent = new AddCompetitionButtonView();

        this._viewCompetitionComponent = new ViewCompetition();
        this._showNotificationsComponent = new ShowNotifications();

        this._handleLoginButtonClick = this._handleLoginButtonClick.bind(this);
        this._handleSignupButtonClick = this._handleSignupButtonClick.bind(this);
        this._handleAddCompetitionButtonClick = this._handleAddCompetitionButtonClick.bind(this);

        this._handleJoinCompetitionClick = this._handleJoinCompetitionClick.bind(this);
        this._handleViewCompetitionCancelClick = this._handleViewCompetitionCancelClick.bind(this);
    }

    init() {
        this._registeredUsers = this._getRegisteredUsers();

        this._ownCompetitionsComponent = new ListCompetitionsView(ListType.OWN);
        this._publicCompetitionsComponent = new ListCompetitionsView(ListType.PUBLIC);
        this._expireCompetitionsComponent = new ListCompetitionsView(ListType.ARCHIVE);
    }

    login(user) {
        this.init();
        this.user = user;
        this.renderPage(PageMode.DEFAULT);
    }

    signup() {
        if (this._ownCompetitionsComponent !== undefined) {
            this._ownCompetitionsComponent.removeElement();
            this._publicCompetitionsComponent.removeElement();
            this._expireCompetitionsComponent.removeElement();
        }
        this.renderPage(PageMode.SIGNUP);
    }

    renderPage(pageMode) {
        console.info(`RenderPage in mode ${pageMode}`);
        this._menuPresenter.renderMenu();

        this._clearContainer(this._contentContainer);
        switch (pageMode) {
            case PageMode.SIGNUP:
                this._renderSignupPage();
                break;
            case PageMode.DEFAULT:
                this._renderDefaultPage();
                break;
            case PageMode.ADDNEW:
                this._renderAddNewPage();
                break;
            case PageMode.VIEW:
                this._renderViewCompetitionPage();
                break;
        }
    }

    set user(value) {
        this._user = new UserModel(value);
        if (this._user.username !== '') {
            this._menuPresenter.username = this._user.shortName;
            this._menuPresenter.renderMenu();
        } else {
            this._menuPresenter.username = '';
        }
    }

    get user() {
        return this._user;
    }

    set competition(value) {
        this._competition = new Competition(value);
        this._viewCompetitionComponent = new ViewCompetition(this._competition);
    }

    get competition() {
        return this._competition;
    }

    get contentContainer() {
        return this._contentContainer;
    }

    _getPublicCompetitions() {
        return db.collection('competitions')
            .where('isPrivate', '==', false)
            .get()
            .then(function(querySnapshot) {
                const competitions = {};
                querySnapshot.forEach(function(doc) {
                    competitions[doc.id] = doc.data();
                    saveNotificationEndCompetition(doc.data());
                })

                return competitions;
            })
            .catch(function (error) {
                console.error(`Error getting user competitions: ${error}`);
            });
    }

    _getNotification() {
        return db.collection('notifications')
            .where("user", "==", this._user.email)
            .where("isViewed", "==", false)
            .get()
            .then(function(querySnapshot) {
                const notifications = {};
                querySnapshot.forEach(function(doc) {
                    notifications[doc.id] = doc.data();
                })

                return notifications;
            })
            .catch(function (error) {
                console.error('Error getting notification: ', error);
            });
    }

    _addLinkByName(data) {
        for (const competitionId in data) {
            if (data.hasOwnProperty(competitionId)) {
                document.getElementById(competitionId).addEventListener('click', () => {
                    this.competition = competitionId;
                    this.renderPage(PageMode.VIEW);
                })
            }
        }
    }

    _getRegisteredUsers() {
        return db.collection("users").get().then((snapshot) => {
            const users = [];
            snapshot.forEach((doc) => {
                users.push(doc.data());
            })
            this._registeredUsers = users;
        });
    }

    _handleLoginButtonClick() {
        console.info('Login button click');

        if (firebase.auth().currentUser) {
            firebase.auth()
                .signOut()
                .then(() => console.info('signOut'));

            this._menuPresenter.username = '';
            this._menuPresenter.renderMenu();

            this.renderPage(PageMode.SIGNUP);
        } else {
            this._handleSignupButtonClick();
        }
    }

    _handleSignupButtonClick() {
        console.info('Signup button click');

        const firebaseUIWidget = document.createElement('section');
        firebaseUIWidget.setAttribute('id', 'firebaseui-auth-container');

        this._clearContainer(this._contentContainer);
        render(this._contentContainer, firebaseUIWidget, RenderPosition.BEFOREEND);

        ui.start('#firebaseui-auth-container', uiConfig);
    }

    _handleAddCompetitionButtonClick() {
        this.renderPage(PageMode.ADDNEW);
    }

    _handleJoinCompetitionClick() {
        console.log('join click');

    }

    _handleViewCompetitionCancelClick() {
        this.renderPage(PageMode.DEFAULT);
    }

    _addNotificationLink(data) {
        for (const notificationId in data) {
            if (data.hasOwnProperty(notificationId)) {
                document.getElementById(`show-competition-${notificationId}`)
                    .addEventListener('click', () => {
                        this.competition = data[notificationId].competitionId;
                        this.renderPage(PageMode.VIEW);

                        const collection = db.collection('notifications');
                        const notification = collection.doc(notificationId);
                        notification.update({isViewed: true})
                            .then(() => {
                                console.info('Notifications was update')
                            }).catch((e) => {
                            console.error('Notifications not update', e);
                        });
                    });
            }
        }
    }

    _clearContainer(container) {
        container.innerHTML = '';
    }

    _renderAddCompetitionButton() {
        render(this._contentContainer, this._addCompetitionButtonComponent, RenderPosition.AFTERBEGIN);
        this._addCompetitionButtonComponent.setClickHandler(this._handleAddCompetitionButtonClick);
    }

    _renderSignupPage() {
        render(this._contentContainer, this._signupComponent, RenderPosition.INSTEAD);
        this._signupComponent.setClickHandler(this._handleSignupButtonClick);
    }

    _renderDefaultPage() {
        Promise.all([
            this.user.getCompetitions(),
            this._getPublicCompetitions()
        ]).then(([userCompetitions, publicCompetitions]) => {
            const expireCompetitions = {};

            const userCompetitionIds = Object.keys(userCompetitions);
            let publicCompetitionIds = Object.keys(publicCompetitions);
            for (const key of publicCompetitionIds) {
                if (userCompetitionIds.includes(key)) {
                    delete publicCompetitions[key]
                }
            }

            for (const key of userCompetitionIds) {
                this._checkExpireCompetition(key, userCompetitions, expireCompetitions)
            }

            publicCompetitionIds = Object.keys(publicCompetitions);
            for (const key of publicCompetitionIds) {
                this._checkExpireCompetition(key, publicCompetitions, expireCompetitions)
            }
            this._renderTable(this._ownCompetitionsComponent, userCompetitions);
            this._renderTable(this._publicCompetitionsComponent, publicCompetitions);
            this._renderTable(this._expireCompetitionsComponent, expireCompetitions);
        }).then(() => {
            this._renderAddCompetitionButton();

            this._getNotification()
                    .then(notifications => {
                        this._showNotificationsComponent = new ShowNotifications(notifications);
                        render(this._contentContainer, this._showNotificationsComponent, RenderPosition.AFTERBEGIN);
                        this._showNotificationsComponent.setShowLaterClickHandler();
                        this._addNotificationLink(notifications);
                    });
        });
    }

    _renderTable(component, competitions) {
        component.removeElement();
        component.competitions = competitions;
        render(this._contentContainer, component, RenderPosition.BEFOREEND);
        this._addLinkByName(competitions);
    }

    _checkExpireCompetition(key, competitions, expireCompetitions) {

        const competition = competitions[key];
        if (competition.endDate !== '' && new Date() > new Date(competition.endDate)) {
            expireCompetitions[key] = competition;
            delete competitions[key];
        }
    }

    _renderAddNewPage() {
        this._addCompetitionPresenter.registeredUsers = this._registeredUsers;
        this._addCompetitionPresenter.render();
    }

    _renderViewCompetitionPage() {
        const owner = {
            email: this._user.email,
            username: this._user.username,
        };

        Promise.all([
            this._checkExistTemplate(owner, this.competition),
            this.competition.getCompetitionById(this.competition)
        ]).then(([isExistTemplate, competition]) => {
                this._viewCompetitionComponent = new ViewCompetition(competition, isExistTemplate);
                render(this._contentContainer, this._viewCompetitionComponent, RenderPosition.INSTEAD);
                //this._viewCompetitionComponent.setJoinClickHandler(this._handleJoinCompetitionClick);
                this._viewCompetitionComponent.setCancelClickHandler(this._handleViewCompetitionCancelClick);
                this._viewCompetitionComponent.setCloseModelClickHandler();
                this._viewCompetitionComponent.setOpenModelClickHandler();
                this._viewCompetitionComponent.setFormSubmitHandler(owner);
                //this._viewCompetitionComponent.setSaveTemplateClickHandler(owner);
            });
    }

    _checkExistTemplate(user, competition) {
        return db.collection('template')
            .where('owner.email', '==', user.email)
            .where("competitionId", "==", competition._competitionId)
            .get()
            .then(function(querySnapshot) {
                return !querySnapshot.empty;
            });
    }
}
