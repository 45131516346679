import AbstractView from './abstract';
import {db} from "../config";
import {getNanoId} from "../utils/tools";
import {CANCEL_VIEW_COMPETITION, JOIN_COMPETITION_BUTTON} from '../utils/const';

const createViewCompetitionTemplate = (competition, isExistTemplate) => {

  const {title, owner, rules, reward, isPrivate, participants, endDate} = competition;

  return `<article class="competition-info">
      <div class="title-section">
        <h2>${title}</h2>

      </div>
      <h3>Создатель соревнования</h3>
      <p>${owner.username}</p>
      <h3>Правила</h3>
      <p>${rules}</p>

      <h3>Список участников</h3>
      ${participants.length > 0
        ? `<div class="table-rating">${createTable(participants, endDate)}</div>`
        : `${isPrivate
            ? `<p>Хм. Кажется, что ${owner.username} создал Приватное соревнование без участников.
                  Если вы увидели это сообщение, сообщите разработчикам.</p>`
            : `<p>Здесь пока никого нет. Стань первым! Нажми кнопку Присоединиться, чтобы принять участие.</p>` }` }
      
      <h3>Награда</h3>
      ${reward !== '' 
        ? `<p>${reward}</p>`
        : `<p>Награда не предусмотрена.</p>`}

      <h3>Дедлайн</h3>
      <p>${endDate === '' ? 'бессрочно' : new Date(endDate).toLocaleDateString()}</p>
      <h3>Соревнование является:</h3>
      ${isPrivate 
        ? '<p>Приватным</p>'
        : `<p>Публичным</p>` }


      <button class="button light-button ${CANCEL_VIEW_COMPETITION}">Назад</button>
    </article>`;
}

function isNotExpire(endDate) {
  return endDate === '' || new Date() <= new Date(endDate);
}

function isCanJoin(endDate, isPrivate) {
  return isNotExpire(endDate) && !isPrivate;
}

function createTable(participants, endDate) {
  let template = '';

  const sortParticipants = participants.sort((a, b) => {
    return (a.ratings.length < b.ratings.length) ? 1 : (a.ratings.length > b.ratings.length) ? -1 : 0;
  });

  for (let participant of sortParticipants) {

    template += `
            <div class="row" id="row-${participant.email}">
                <div class="label">${participant.username}</div>
                <div class="stars">${getStars(participant.ratings)}</div>
                ${isNotExpire(endDate) ?
                  `<button type="button" class="add-stars" id="add-stars-${participant.email}">&#10010;</button>`: ''}
            </div>
            <div class="modal" id="modal-${participant.email}">
                <form class="modal-content" id="form-${participant.email}">
                    <button type="button" class="modal-close-button">&#10060;</button>
                    <label class="modal-label">За что начислен балл</label>
                    <textarea name="starsInfo" class="modal-input"></textarea>
                    <button type="submit" class="modal-button button dark-button">Добавить</button>
                </form>
            </div>`;
  }

  return template;
}

function getStars(ratings) {
  let stars = '';

  for (let rating of ratings) {
    stars += `<div class="star">
                <img class="star" src="img/star.png" alt="star" id="star-${rating.id}">
                <span class="tooltiptext" id="tooltip-${rating.id}">
                  <p>Кто: ${rating.username}</p>
                  <p>Когда: ${new Date(rating.timestamp).toLocaleDateString()}</p>
                  <p>Текст: ${rating.text || 'Нет'}</p>
                </span>
            </div>`;
  }

  return stars;
}

export default class ViewCompetition extends AbstractView {
  constructor(competition = null, isExistTemplate = false) {
    super();
    this._competition = competition;
    this._isExistTemplate = isExistTemplate;

    this._joinClickHandler = this._joinClickHandler.bind(this);
    this._cancelClickHandler = this._cancelClickHandler.bind(this);
  }

  getTemplate() {
    return createViewCompetitionTemplate(this._competition, this._isExistTemplate);
  }

  get competition() {
    return this._competition;
  }

  _joinClickHandler(evt) {
    evt.preventDefault();
    this._callback.joinClick();
  }

  _cancelClickHandler(evt) {
    evt.preventDefault();
    this._callback.cancelClick();
  }

  setJoinClickHandler(callback) {
    this._callback.joinClick = callback;
    if (isCanJoin(this._competition.endDate, this._competition.isPrivate)) {
      this.getElement().querySelector(`.${JOIN_COMPETITION_BUTTON}`).addEventListener('click', this._joinClickHandler);
    }
  }

  setCancelClickHandler(callback) {
    this._callback.cancelClick = callback;
    this.getElement().querySelector(`.${CANCEL_VIEW_COMPETITION}`).addEventListener('click', this._cancelClickHandler);
  }

  setCloseModelClickHandler() {

    const nodeList = this.getElement().querySelectorAll(`.modal-close-button`);
    nodeList.forEach(node => {
      node.addEventListener('click', () => {
        const nodeList = this.getElement().querySelectorAll(`.modal`);
        nodeList.forEach(node => {
          node.style.display = "none";
        });
      });
    });
  }

  setOpenModelClickHandler() {
    this._competition.participants.forEach(participant => {

      if (this._competition.endDate === '' || new Date() <= new Date(this._competition.endDate)) {

        document.getElementById(`add-stars-${participant.email}`).addEventListener('click', () => {
          const modal = document.getElementById(`modal-${participant.email}`)
          modal.style.display = "block";
          modal.querySelector('.modal-content').reset();
        });
      }

      participant.ratings.forEach(rating => {
        const star = document.getElementById(`star-${rating.id}`)
        const tooltip = document.getElementById(`tooltip-${rating.id}`)

        star.onmousemove = function(e) {
          const x = e.clientX;
          tooltip.style.left = (x + 30) + 'px';
        }
      });
    });
  }

  _formSubmitHandler(user, participant, evt) {
    evt.preventDefault();

    const form = evt.target;
    const starsInfo = form.elements['starsInfo'].value;

    const ratingInfo = {
      id: getNanoId(),
      email: user.email,
      username: user.username,
      timestamp: new Date().getTime(),
      text: starsInfo,
    }

    const collection = db.collection('competitions');
    const competition = collection.doc(this._competition.id);

    participant.ratings.push(ratingInfo);
    const newParticipants = this._competition.participants.map(obj =>
        obj.email === participant.email ? { ...obj, ratings: participant.ratings } : obj
    );

    if (this._competition.endDate !== '' && new Date() > new Date(this._competition.endDate)) {
      console.error('Competition is ended')
      return;
    }

    competition.update({participants: newParticipants}).then(() => {
      console.info('Star info was added')
      let modal = document
          .getElementById(`modal-${participant.email}`)
      modal.style.display = "none";

      let starsBlock = document
          .getElementById(`row-${participant.email}`)
          .querySelector('.stars')



      const block = `<div class="star">
                <img class="star" src="img/star.png" alt="star" id="star-${ratingInfo.id}">
                <span class="tooltiptext" id="tooltip-${ratingInfo.id}">
                  <p>Кто: ${ratingInfo.username}</p>
                  <p>Когда: ${new Date(ratingInfo.timestamp).toLocaleDateString()}</p>
                  <p>Текст: ${ratingInfo.text || '-'}</p>
                </span>
            </div>`

      starsBlock.insertAdjacentHTML('beforeend', block);
    }).catch((e) => {
      console.error('Star was not add', e);
    })
  }

  setFormSubmitHandler(user) {
    this._competition.participants.forEach(participant => {
      document.getElementById(`form-${participant.email}`)
          .addEventListener('submit', this._formSubmitHandler.bind(this, user, participant));
    });
  }

  setSaveTemplateClickHandler(user) {

    if (this._isExistTemplate) {
      return;
    }

    const {id, title, rules, reward, isPrivate, participants} = this._competition;

    this.getElement().querySelector(`.save-template`)
        .addEventListener('click', () => {

          const clearParticipants = [];
          for (const participant of participants) {
            clearParticipants.push({
              email: participant.email,
              username: participant.username,
              ratings: [],
            });
          }

          const competition = {
            id: getNanoId(),
            title,
            rules,
            reward,
            startDate: new Date(),
            endDate: '',
            isPrivate,
            owner: user,
            participants: clearParticipants,
            competitionId: id
          };

          db.collection('template').doc(competition.id).set(competition)
              .then(() => {
                console.info("Template successfully written!");
                const button = this.getElement().querySelector(`.save-template`);
                button.style.display = 'none';
              });
        });
  }
}
