import { db } from '../config';

export const DEFAULT_COMPETITION = {
    id: '',
    title: '',
    rules: '',
    reward: '',
    startDate: Date.now(),
    endDate: '',
    isPrivate: false,
    owner: {
        email: '',
        username: '',
    },
    participants: [],
}

export default class Competition {
    constructor(competition = DEFAULT_COMPETITION) {
        this._competitionId = competition;
    }

    get id() {
        return this._competitionId;
    }

    getCompetitionById() {
        return db.collection('competitions').doc(this.id).get()
            .then(function (doc) {
                if (doc.exists) {
                    console.info(`doc exists`);
                    return doc.data();
                } else {
                    console.log(`Competition with ID ${this.id} not found`);
                }
            })
            .catch(function (error) {
                console.error(`Can't get competition by ID ${this.id}`);
            });
    }
}
