import AbstractView from './abstract.js';
import {renderTable} from "../utils/tools";


export const ListType = {
    OWN: {
        id: `own-competitions`,
        title: `Мои соревнования`,
        emptyText: `Здесь пока ничего нет. Создай своё первое соревнование с помощью кнопки + Новое соревнование.`,
    },
    PUBLIC: {
        id: `public-competitions`,
        title: `Открытые соревнования`,
        emptyText: `Никто не создал открытое соревнование.`,
    },
    ARCHIVE: {
        id: `archive-competitions`,
        title: `Завершенные соревнования`,
        emptyText: `Успей присоединиться к соревнованию, пока они не завершились!`
    }
}

const createListCompetitionsTemplate = (competitions, type) => {
    const container = document.createElement('article');
    container.id = type.id;
    container.className = 'container';

    const titleBlock = document.createElement('h2');
    titleBlock.className = 'title';
    titleBlock.textContent = type.title;

    container.appendChild(titleBlock);

    if (Object.keys(competitions).length === 0) {
        const empty = document.createElement('div');
        empty.className = 'title wrapper empty';
        empty.textContent = type.emptyText;
        container.appendChild(empty);
    } else {
        const wrapperBlock = renderTable(competitions);
        container.appendChild(wrapperBlock);
    }

    return container.outerHTML;
};

export default class ListCompetitions extends AbstractView {
    constructor(type) {
        super();
        this._competitions = {};
        this._type = type;
    }

    set competitions(value) {
        this._competitions = value;
    }
    
    getTemplate() {
        return createListCompetitionsTemplate(this._competitions, this._type)
    }
}