import AddCompetitionView from '../views/add-competition';
import { PageMode, render, RenderPosition } from '../utils/render';
import { getNanoId } from '../utils/tools';
import { firebase, db } from '../config';
import CompetitionModel from '../models/competition';
import { ADD_COMPETITION_FORM, REGISTERED_USERS } from '../utils/const';

export default class AddCompetition {
    constructor(container, board) {
        this._mainContainer = container;
        this._boardPresenter = board;

        this._addCompetitionComponent = null;

        this._handleSaveCompetitionClick = this._handleSaveCompetitionClick.bind(this);
        this._handleCancelCompetitionClick = this._handleCancelCompetitionClick.bind(this);
        this._handleAddParticipantsClick = this._handleAddParticipantsClick.bind(this);

        this._selectedUsers = new Map();

        this.init();
    }

    init() {
        this._competition = new CompetitionModel();
        this._addCompetitionComponent = new AddCompetitionView();
    }

    render() {
        render(this._mainContainer, this._addCompetitionComponent, RenderPosition.INSTEAD);

        this._clearForm();

        this._addCompetitionComponent.setFormSubmitHandler(this._handleSaveCompetitionClick);
        this._addCompetitionComponent.setCancelClickHandler(this._handleCancelCompetitionClick);
        this._addCompetitionComponent.setAddUserHandler(this._handleAddParticipantsClick);
    }

    set registeredUsers(value) {
        this._addCompetitionComponent.users = value;
    }

    _clearForm() {
        document.querySelector(`.${ADD_COMPETITION_FORM}`).reset();
        this._selectedUsers.clear();
        this._addCompetitionComponent.updateSelectedUsersView();
    }

    _handleSaveCompetitionClick() {
        const title = document.getElementById('title').value;
        const rules = document.getElementById('rules').value;
        const reward = document.getElementById('reward').value;
        const startDate = Date.now();
        const endDate = document.getElementById('end-date').value;
        const owner = {
            email: this._boardPresenter.user.email,
            username: this._boardPresenter.user.username,
        };
        const isPrivate = document.getElementById('is-private').checked;

        const participants = [];
        for (const [email, username] of this._selectedUsers) {
            participants.push({
                email,
                username,
                ratings: [],
            });
        }

        const competition = {
            id: getNanoId(),
            title,
            rules,
            reward,
            startDate,
            endDate,
            isPrivate,
            owner,
            participants,
        };

        db.collection('competitions').doc(competition.id).set(competition)
            .then(() => {
                console.info("Document successfully written!");

                for (const user of participants) {
                    db.collection('users').doc(user.email)
                        .update({
                            competitions: firebase.firestore.FieldValue.arrayUnion(competition.id),
                        })
                        .then(() => console.info(`Adding competition to users db`));

                    const notificationInfo = {
                        id: getNanoId(),
                        competitionId: competition.id,
                        isViewed: false,
                        message: `Вы были добавлены в новое соревнование "${competition.title}"`,
                        user: user.email,
                        type: 'create'
                    }

                    db.collection('notifications')
                        .doc(notificationInfo.id)
                        .set(notificationInfo)
                        .then(() => console.info(`Adding notifications`));
                }

                this._boardPresenter.user.addCompetition(competition);
                this._boardPresenter.renderPage(PageMode.DEFAULT);
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
    }

    _handleCancelCompetitionClick() {
        this._boardPresenter.renderPage(PageMode.DEFAULT);
    }

    _handleAddParticipantsClick() {
        console.log('Add users click');
        const selectedUser = document.querySelector(`.${REGISTERED_USERS}`);
        this._selectedUsers.set(selectedUser.value, selectedUser.options[selectedUser.selectedIndex].text.split(' (')[0]);
        this._addCompetitionComponent.updateSelectedUsersView(this._selectedUsers);
        this._addLinkByName(this._selectedUsers);
        document.querySelector(`.${REGISTERED_USERS}`).focus();
    }

    _addLinkByName(data) {
        for (const [email] of data) {
            document.getElementById(email).addEventListener('click', (evt) => {
                evt.preventDefault();
                console.log(`close for ${email}`);
                this._selectedUsers.delete(email);
                this._addCompetitionComponent.updateSelectedUsersView(this._selectedUsers);
                this._addLinkByName(this._selectedUsers);
            })
        }
    }
}
