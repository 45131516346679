import AbstractView from "./abstract";
import {LOGIN_BUTTON} from '../utils/const';

const createSiteMenuTemplate = (username) => {

    const hidden = username === '' ? 'hidden' : '';
    const loginButtonTitle = username === '' ? 'Войти' : 'Выйти';

    return `<header class="main-nav">
            <div class="logo">
                <img src="img/logo.png" alt="Логотип">
            </div>
            <div class="title"><h1>Весёлые соревнования!</h1></div>
            <div class="links">
                <p class="username ${hidden}">${username}</p>
                <button class="button dark-button ${LOGIN_BUTTON}">${loginButtonTitle}</button>
            </div>
        </header>`;
};

export default class SiteMenu extends AbstractView {
    constructor(username) {
        super();
        this._username = username || '';

        this._loginClickHandler = this._loginClickHandler.bind(this);
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value || '';
    }

    getTemplate() {
        return createSiteMenuTemplate(this._username);
    }

    _loginClickHandler(evt) {
        evt.preventDefault();
        this._callback.loginClick();
    }

    setLoginClickHandler(callback) {
        this._callback.loginClick = callback;
        this.getElement().querySelector(`.${LOGIN_BUTTON}`).addEventListener('click', this._loginClickHandler);
    }
}
