import AbstractView from "./abstract.js";

const createNotificationTemplate = (notifications) => {

    return `<div class="notifications">
        ${getNotifications(notifications)}
    </div>`;
};

function getNotifications(notifications) {
    let template = ``;

    for (const notificationId in notifications) {
        if (notifications.hasOwnProperty(notificationId)) {

            const row = notifications[notificationId];

            template += `
            <div class="notification" id="notification-${notificationId}">
                <span>${row.message}</span>
                <div class="buttons-block">                
                    <button class="button dark-button" id="show-competition-${notificationId}">Просмотреть</button>
                    <button class="button light-button" id="show-later-${notificationId}">Позже</button>
                </div>
            </div>`;
        }
    }

    return template;
}

export default class ShowNotifications extends AbstractView {
    constructor(notifications = null) {
        super();
        this._notifications = notifications;
    }

    getTemplate() {
        return createNotificationTemplate(this._notifications);
    }

    setShowLaterClickHandler() {

        for (const notificationId in this._notifications) {
            if (this._notifications.hasOwnProperty(notificationId)) {
                document.getElementById(`show-later-${notificationId}`)
                    .addEventListener('click', () => {
                        const modal = document.getElementById(`notification-${notificationId}`)
                        modal.style.display = "none";
                    });
            }
        }
    }
}
