import { firebase, db } from '../config';
import {saveNotificationEndCompetition} from "../utils/tools";

const DEFAULT_USER = {
    displayName: '',
    email: '',
}

export default class UserModel {
    constructor(user = DEFAULT_USER) {
        this._username = user.displayName;
        this._email = user.email;
        this._competitions = {};

        this._init(user);
    }

    _init(user) {
        this._createUserIfNotExist(user);
        this._updateCompetitions();
    }

    get username() {
        return this._username;
    }

    get email() {
        return this._email;
    }

    /*get competitions() {
        return this._competitions;
    }

    set competitions(value) {
        this._competitions = value;
    }*/

    get shortName() {
        return this._getShortUsername();
    }

    addCompetition(competition) {
        db.collection('users').doc(this._email)
            .update({
                competitions: firebase.firestore.FieldValue.arrayUnion(competition.id),
            });
        this._updateCompetitions();
    }

    getCompetitions() {
        return this._competitions;
    }

    _createUserIfNotExist(user) {
        if (user.displayName === '') {
            return;
        }
        db.collection('users').doc(user.email).get()
            .then(function (userAccount) {
                if (userAccount.exists) {
                    console.info(`Account for ${user.displayName} exists`);
                } else {
                    console.info(`Account for ${user.displayName} doesn't exist\nTrying to create it`)
                    db.collection('users').doc(user.email).set({
                        username: user.displayName,
                        email: user.email,
                        competitions: [],
                    })
                        .then(function () {
                            console.info(`Account for ${user.displayName} was created`);
                        })
                        .catch(function (error) {
                            console.error(`Can't create user account for ${user.displayName}\nReason: ${error}`);
                        })
                }
            })
            .catch(function (error) {
                console.error(`Can't check user existence\nReason:`, error);
            });
    }
    
    _getUserCompetitions() {
        return db.collection('users').doc(this._email).get()
            .then((user) => {
                if (user.data().competitions.length === 0) {
                    return {};
                }
                return db.collection('competitions')
                    .where('id', 'in', user.data().competitions)
                    .get()
                    .then((snapshot) => {
                        const competitions = {};
                        snapshot.forEach((doc) => {
                            competitions[doc.id] = doc.data();
                            saveNotificationEndCompetition(doc.data());
                        })
                        return competitions;
                    });
            });
    }

    _getShortUsername() {
        const [firstName, lastName] = this._username.split(' ');

        return `${firstName} ${lastName ? lastName[0]+'.' : ''}`;
    }

    _updateCompetitions() {
        this._competitions = this._getUserCompetitions();
    }
}
