import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import * as firebaseui from 'firebaseui';

const firebaseConfig = {
    apiKey: "AIzaSyC1AnvIGaeNuTGWL6MVEbUl-Nv0geHUzEU",
    authDomain: "fir-auth-88e5f.firebaseapp.com",
    databaseURL: "https://fir-auth-88e5f.firebaseio.com",
    projectId: "fir-auth-88e5f",
    storageBucket: "fir-auth-88e5f.appspot.com",
    messagingSenderId: "97277758880",
    appId: "1:97277758880:web:3510989f20223bb4d868c5"
};

export const uiConfig = {
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        // Handle sign-in.
        // Return false to avoid redirect.
        return false;
      }
    }
};

firebase.initializeApp(firebaseConfig);

const ui = new firebaseui.auth.AuthUI(firebase.auth());
const db = firebase.firestore();

export {firebase, ui, db};
