import { remove, render, RenderPosition } from '../utils/render';
import { uiConfig, ui } from '../config';
import SiteMenuView from '../views/site-menu';
import firebase from 'firebase/app';

export default class SiteMenu {
    constructor(siteMenuContainer, boardPresenter, shortUsername = '') {
        this._siteMenuContainer = siteMenuContainer;
        this._boardPresenter = boardPresenter;
        
        this._username = shortUsername;

        this._siteMenuComponent = new SiteMenuView(this._username);

        this._handleLoginButtonClick = this._handleLoginButtonClick.bind(this);
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value;
        this._siteMenuComponent = new SiteMenuView(this._username);
    }

    renderMenu() {
        render(this._siteMenuContainer, this._siteMenuComponent, RenderPosition.INSTEAD);
        this._siteMenuComponent.setLoginClickHandler(this._handleLoginButtonClick);
    }

    _handleLoginButtonClick() {
        console.info(`SiteMenu Presenter Login button click`);

        if (firebase.auth().currentUser) {
            firebase.auth()
                .signOut()
                .then(() => console.log(`SiteMenu Login button click: user sign out`));
            remove(this._siteMenuComponent);

            this._username = '';
            this._siteMenuComponent = new SiteMenuView();
            this.renderMenu();
            this._boardPresenter.signup();
        } else {
            console.info('login checking')
            remove(this._siteMenuComponent);
            this._siteMenuComponent = new SiteMenuView(this._username);
            this.renderMenu();
            const firebaseUIWidget = document.createElement('section');
            firebaseUIWidget.setAttribute('id', 'firebaseui-auth-container');

            render(this._boardPresenter.contentContainer, firebaseUIWidget, RenderPosition.INSTEAD);

            ui.start('#firebaseui-auth-container', uiConfig);
        }
    }
}
