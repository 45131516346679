import dayjs from 'dayjs';
import {db} from "../config";

export const getNanoId = (t=21) => {
    let e="",r=crypto.getRandomValues(new Uint8Array(t));for(;t--;){let n=63&r[t];e+=n<36?n.toString(36):n<62?(n-26).toString(36).toUpperCase():n<63?"_":"-"}return e;
};

export const renderTable = (competitions) => {

    let wrapperBlock = document.createElement('div');
    wrapperBlock.className = 'wrapper';

    const tableBlock = document.createElement('div');
    tableBlock.className = 'table';

    const headTableBlock = document.createElement('div');
    headTableBlock.className = 'row row-head';

    const headTitleObj = [
        {id: 'name', name: 'Название'},
        {id: 'participant', name: 'Участники'},
        {id: 'end-date', name: 'Дедлайн'}
    ];

    for (let row of headTitleObj) {
        const headCellBlock = document.createElement('div');
        headCellBlock.className = `cell head ${row.id}`;
        headCellBlock.textContent = row.name;

        headTableBlock.appendChild(headCellBlock);
    }

    wrapperBlock.appendChild(tableBlock);
    tableBlock.appendChild(headTableBlock);

    for (const competitionId in competitions) {
        const rowTableBlock = document.createElement('div');
        rowTableBlock.className = 'row';
        rowTableBlock.id = competitionId;
        rowTableBlock.setAttribute('style', 'cursor: pointer;');

        const row = competitions[competitionId];
        // cell name
        const cellName = document.createElement('div');
        cellName.className = `cell name`;

        const cellNameData = document.createElement('div');
        cellNameData.className = `content`;
        cellNameData.textContent = row.title;

        cellName.appendChild(cellNameData);
        rowTableBlock.appendChild(cellName);

        // cell participant
        const cellParticipant = document.createElement('div');
        cellParticipant.className = `cell participant`;

        const cellParticipantData = document.createElement('div');
        cellParticipantData.className = `content`;
        cellParticipantData.textContent = row.participants.length.toString();

        const cellParticipantHead = document.createElement('div');
        cellParticipantHead.className = `cell-head`;
        cellParticipantHead.textContent = 'Участники';

        cellParticipant.appendChild(cellParticipantHead);
        cellParticipant.appendChild(cellParticipantData);
        rowTableBlock.appendChild(cellParticipant);

        // cell end-date
        const cellEndDate = document.createElement('div');
        cellEndDate.className = `cell end-date`;

        const cellEndDateData = document.createElement('div');
        cellEndDateData.className = `content`;
        cellEndDateData.textContent = row.endDate === ''
            ? 'бессрочно'
            : dayjs(row.endDate).format('DD MMM YYYY');

        const cellEndDateHead = document.createElement('div');
        cellEndDateHead.className = `cell-head`;
        cellEndDateHead.textContent = 'Дедлайн';

        cellEndDate.appendChild(cellEndDateHead);
        cellEndDate.appendChild(cellEndDateData);
        rowTableBlock.appendChild(cellEndDate);

        tableBlock.appendChild(rowTableBlock);
    }

    return wrapperBlock;
}

export const saveNotificationEndCompetition = (competition) => {

    if (competition.endDate === '' || new Date() <= new Date(competition.endDate)) {
        return;
    }

    for (const user of competition.participants) {

        db.collection('notifications')
            .where('user', '==', user.email)
            .where('competitionId', '==', competition.id)
            .where('type', '==', 'deleted')
            .get()
            .then(function(querySnapshot) {

                if (!querySnapshot.empty) {
                    return;
                }

                const notificationInfo = {
                    id: getNanoId(),
                    competitionId: competition.id,
                    isViewed: false,
                    message: `Cоревнование "${competition.title}" было завершено`,
                    user: user.email,
                    type: 'deleted'
                }

                db.collection('notifications').doc(notificationInfo.id).set(notificationInfo);
            });
    }
}
