import AbstractView from './abstract.js';
import {RenderPosition, renderTemplate} from '../utils/render';
import {DEFAULT_COMPETITION} from '../models/competition';
import {ADD_COMPETITION_FORM, ADD_USER_BUTTON, CANCEL_NEW_COMPETITION, PARTICIPANTS, REGISTERED_USERS, SAVE_NEW_COMPETITION} from '../utils/const';

const createUsersListTemplate = (users) => {
    let template = '';
    for (const user of users) {
        template += `<option value="${user.email}" data-username="${user.username}">${user.username} (${user.email})</option>`;
    }

    return `<label>
        Выбери участника
        <select name="registeredUsers" class="${REGISTERED_USERS} select-users">
            ${template}
        </select>
    </label>
    <button class="button light-button ${ADD_USER_BUTTON}">Добавить участника</button>`;
}


const createCompetitionTemplate = (users) => {
    return `<article class="new-competition-form">
        <h2 class="title">Создать соревнование</h2>
        <form class="${ADD_COMPETITION_FORM}">
            <div class="form-group">
                <label for="title" class="label">Название*</label>
                <input autofocus="autofocus" type="text" id="title" required>
            </div>
            <div class="form-group">
                <label for="description" class="label">Правила*</label>
                <textarea id="rules" cols="15" rows="3" required></textarea>
            </div>
            <div class="form-group">
                <label for="reward" class="label">Награда</label>
                <textarea id="reward" cols="15" rows="3"></textarea>
            </div>
            <div class="form-group">
                <label for="end-date" class="label">Дата окончания</label>
                <input type="date" id="end-date">
            </div>
            <div class="form-group">
                <label for="is-private">Приватное соревнование</label>
                <input type="checkbox" id="is-private">
            </div>
            <section>
                ${createUsersListTemplate(users)}                
                <section class="${PARTICIPANTS}">                    
                </section>
            </section>
            <section class="buttons-block">
                <button type="submit" class="button dark-button ${SAVE_NEW_COMPETITION}">Сохранить</button>
                <button type="button" class="button light-button ${CANCEL_NEW_COMPETITION}">Отменить</button>
            </section>
        </form>
    </article>`;
};


export default class AddCompetition extends AbstractView {
    constructor() {
        super();
        this._competition = DEFAULT_COMPETITION;

        this._formSubmitHandler = this._formSubmitHandler.bind(this);
        this._cancelClickHandler = this._cancelClickHandler.bind(this);
        this._addUsersHandler = this._addUsersHandler.bind(this);
    }

    set users(value) {
        this._users = value;
    }

    getTemplate() {
        return createCompetitionTemplate(this._users);
    }

    updateSelectedUsersView(selectedUsers) {
        const participantsContainer = document.querySelector(`.${PARTICIPANTS}`);
        participantsContainer.innerHTML = '';

        let template = '';
        if (!selectedUsers) {
            return;
        }
        for (const [email, username] of selectedUsers) {
            template += `<div>
                <label for="${email}">${username} (${email})</label>
                <button class="modal-close-button" id="${email}">&#10060;</button>
            </div>`;
        }

        renderTemplate(participantsContainer, template, RenderPosition.AFTERBEGIN);
    }

    _formSubmitHandler(evt) {
        evt.preventDefault();
        this._callback.formSubmit(this._competition);
    }

    _cancelClickHandler(evt) {
        evt.preventDefault();
        this._callback.cancelClick();
    }

    _addUsersHandler(evt) {
        evt.preventDefault();
        this._callback.addUsers();
    }

    setFormSubmitHandler(callback) {
        this._callback.formSubmit = callback;
        this.getElement().querySelector(`.${ADD_COMPETITION_FORM}`)
            .addEventListener('submit', this._formSubmitHandler);
    }

    setCancelClickHandler(callback) {
        this._callback.cancelClick = callback;
        this.getElement().querySelector(`.${CANCEL_NEW_COMPETITION}`)
            .addEventListener('click', this._cancelClickHandler);
    }

    setAddUserHandler(callback) {
        this._callback.addUsers = callback;
        this.getElement().querySelector(`.${ADD_USER_BUTTON}`)
            .addEventListener('click', this._addUsersHandler);
    }
}
