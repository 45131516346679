import AbstractView from "./abstract.js";
import {ADD_COMPETITION_BUTTON} from '../utils/const';

const createAddCompetitionButtonTemplate = () => {
    return `<section class="add-competition">
        <button class="button light-button ${ADD_COMPETITION_BUTTON}">+ Новое соревнование</button>
    </section>`;
};

export default class AddCompetitionButton extends AbstractView {
    constructor() {
        super();

        this._clickHandler = this._clickHandler.bind(this);
    }

    getTemplate() {
        return createAddCompetitionButtonTemplate();
    }

    _clickHandler(evt) {
        evt.preventDefault();
        this._callback.click();
    }

    setClickHandler(callback) {
        this._callback.click = callback;
        this.getElement().querySelector(`.${ADD_COMPETITION_BUTTON}`)
            .addEventListener('click', this._clickHandler);
    }
}
